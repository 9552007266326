import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

// Contact page component
const ContactPage = () => (
  // Wrap contents in layout component
  <Layout>
    <SEO
      title="Contact"
      description="Get in touch with our distributor G.A.S to find a stockist, become a stockist or for any other queries."
    />
    {/* Page introduction */}
    <section className="stockist-page">
      <div className="stockist-page-inner">
        <div className="text-area" style={{ marginTop: 0 }}>
          <h1 style={{ color: "#E1873D" }}>Contact</h1>
          <h3
            style={{
              fontWeight: "normal",
              fontSize: "26px",
              marginTop: "8px",
            }}
          >
            Global Air Supplies are our UK distributors. Get in touch with them
            to discuss becoming a stockist.
          </h3>
        </div>

        {/* Representative information */}
        <div>
          <div className="card-right">
            {/* Dynamic details depending on location */}
            <h4>UK Distributor</h4>
            <h1 style={{ margin: 0 }}>G.A.S</h1>
            <ul className="social-links">
              <li>
                <a
                  href={`mailto:sales@globalairsupplies.co.uk`}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  sales@globalairsupplies.co.uk
                </a>
              </li>
              <li>
                <a
                  href="tel:+441226 748613"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  01226 748 613
                </a>
              </li>
              <li style={{ marginTop: "12px" }}>
                <a
                  className="mock-button"
                  href="https://www.globalairsupplies.co.uk"
                  rel="noreferrer noopener"
                  target="_blank"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  GlobalAirSupplies.co.uk
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* Page title in background */}
        <h1 className="transparent-title">Contact</h1>
      </div>
    </section>
  </Layout>
)

export default ContactPage
